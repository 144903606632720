/*.navbar {*/
/*    !* your existing styles *!*/
/*    opacity: 0;*/
/*    transition: opacity 0.5s ease-in-out;*/
/*}*/

.navbar-start{
    width: 40%;
}

.navbar-end{
    width: 60%;
}

/*.navbar.visible {*/
/*    opacity: 1;*/
/*}*/